import React, { FC, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { Clipboard } from 'react-feather';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import { animateScroll as scroll } from 'react-scroll';

import { BadgeStyles } from '../styles/content.styles';
import { ParseFile } from '../utils/parseFile';
import { ToastSettings } from '../utils/toastSettings';

import { CodeContext } from '../providers/codeWrapper';
import { EditorContext } from '../providers/types';

import 'react-toastify/dist/ReactToastify.css';

const InfoStyles = styled.span`
    position: relative;
    top: -1.3em;
    font-size: 0.9em;
`;

const ToastStyles = styled.div`
    .toast-wrapper {
        border: solid 2px;
    }
`;

export const Upload: FC = () => {
    const { setCode } = useContext<EditorContext>(CodeContext);

    const onDrop = useCallback(
        (acceptedFiles, fileRejections, event) => {
            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);

            reader.onload = () => {
                if (reader.result) {
                    const result = ParseFile(reader.result);
                    if (result instanceof Error) {
                        toast.error('Error: ' + result.message, {
                            ...ToastSettings,
                        });
                    } else {
                        setCode(result);
                        toast.success('File Upload Success', {
                            ...ToastSettings,
                        });
                        scroll.scrollToBottom('codeEditor');
                    }
                }
            };
        },
        [setCode]
    );

    const { getInputProps, open } = useDropzone({
        maxFiles: 1,
        noClick: true,
        noKeyboard: true,
        accept: ['application/json', '.geojson'],
        onDrop,
    });

    return (
        <div>
            <input {...getInputProps()} />
            <button className="btn" onClick={open}>
                Upload <Clipboard />
            </button>
            <InfoStyles>
                <i>.json & .geojson</i>
            </InfoStyles>
            <BadgeStyles>New!</BadgeStyles>
            <ToastStyles>
                <ToastContainer toastClassName="toast-wrapper" />
            </ToastStyles>
        </div>
    );
};
