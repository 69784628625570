import React, { FC, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { ToggleLeft, ToggleRight, Eye, EyeOff, Home, Maximize2, Minimize2 } from 'react-feather';
import { CSSTransition } from 'react-transition-group';
import { Terminal, ChevronsDown } from 'react-feather';
import { Link } from 'react-scroll';

import { Upload } from '../components/upload';
import Map from '../components/map';
import { Editor } from '../components/editor';
import { CodeContext } from '../providers/codeWrapper';
import { EditorContext, Preferences, Themes } from '../providers/types';

import { ContentStyles } from '../styles/content.styles';
import { CodeStyles, TooltipStyles, InputWrapper } from '../styles/code.styles';

export const ContentWrapper: FC = () => {
    const { codeError, preferences, setPreferences } = useContext<EditorContext>(CodeContext);

    const updateState = (e: React.ChangeEvent<HTMLSelectElement> | undefined, type?: string) => {
        const previous = type ? preferences[type as keyof Preferences] : e ? preferences[e.target.name as keyof Preferences] : undefined;
        if (type && typeof previous === 'boolean') {
            setPreferences((prev) => ({
                ...prev,
                [type]: !previous,
            }));
        } else if (typeof previous === 'string' && e) {
            setPreferences((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <main className="main-container">
                <ContentStyles>
                    <h1>
                        Welcome To <i>Geo-JSON</i>.com
                    </h1>
                    <h3>Create or upload your custom GeoJSON map</h3>
                    <div className="buttons-wrapper">
                        <div className="buttons">
                            <Link className="btn-link" to="codeEditor" smooth={true} duration={800} data-cy="create-btn">
                                Create <Terminal />
                            </Link>
                            <Upload />
                        </div>
                    </div>
                    <Link className="down-link center" to="codeEditor" smooth={true} duration={800}>
                        <ChevronsDown />
                    </Link>
                </ContentStyles>
            </main>
            <aside id="codeEditor">
                <InputWrapper>
                    <div className="select-wrapper">
                        <select name="theme" value={preferences.theme} onChange={updateState}>
                            <option value={Themes.DEFAULT}>Default</option>
                            <option value={Themes.DARK}>Dark</option>
                            <option value={Themes.BASIC}>Basic</option>
                        </select>
                    </div>
                    <div
                        data-cy="tilt-fn"
                        className={classNames('toggle-wrapper', { disabled: !preferences.tiltMap })}
                        onClick={() => updateState(undefined, 'tiltMap')}
                    >
                        <span>Tilt Map</span>
                        {preferences.tiltMap ? <ToggleLeft /> : <ToggleRight />}
                    </div>
                    <div
                        data-cy="toggle-editor"
                        className={classNames('toggle-wrapper', { disabled: !preferences.showEditor })}
                        onClick={() => updateState(undefined, 'showEditor')}
                    >
                        <span>Toggle Editor</span>
                        {preferences.showEditor ? <Eye /> : <EyeOff />}
                    </div>
                    <div
                        data-cy="toggle-building"
                        className={classNames('toggle-wrapper', { disabled: !preferences.showBuildings })}
                        onClick={() => updateState(undefined, 'showBuildings')}
                    >
                        <span>Display 3D Buildings</span>
                        {preferences.showBuildings ? <Home /> : <Home />}
                    </div>
                    <div
                        data-cy="toggle-map-width"
                        className={classNames('toggle-wrapper map-width-wrapper')}
                        onClick={() => updateState(undefined, 'maximizeWidth')}
                    >
                        <span>{!preferences.maximizeWidth ? 'Maximize' : 'Minimize'} Map</span>
                        {!preferences.maximizeWidth ? <Maximize2 /> : <Minimize2 />}
                    </div>
                </InputWrapper>
                <CodeStyles showEditor={preferences.showEditor} maximizeWidth={preferences.maximizeWidth}>
                    <div className={classNames('one-grid', { errorWrap: codeError && codeError.length })}>
                        <CSSTransition in={preferences.showEditor} timeout={3000} classNames="alert" unmountOnExit>
                            <Editor />
                        </CSSTransition>
                    </div>
                    <div className="second-grid">
                        <Map />
                    </div>
                </CodeStyles>
                {codeError && codeError.length ? (
                    <TooltipStyles>
                        {codeError[0].type}: {codeError[0].text}, row: {codeError[0].row}, column: {codeError[0].column}
                    </TooltipStyles>
                ) : (
                    ''
                )}
            </aside>
        </Fragment>
    );
};
