import { createGlobalStyle } from 'styled-components';

import BGImage from '../assets/concrete-wall.png';

export const GlobalStyles = createGlobalStyle`
    :root {
        --black: #2E2E2E;
        --yellow: #ffc600;
        --white: #fff;
        --grey: #efefef;
        --primaryFg: var(--black);
        --greyFont: #9a9a9a;
        --lightGrey: #f3f3f3;
        --lightBlue: #2a4fd7;
        --blue: #2B3245;
        --darkBlue: #212735;
        --error: #d72323;
        --smallFont: 1.2em;
        --regularFont: 1.5em;
        --largeFont: 1.8em;
        --extraLargeFont: 2.2em;
        --smallSpacing: 20px;
        --mediumSpacing: 40px;
        --largeSpacing: 60px;
    }
    html, * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        outline: none;
    }
    html {
        font-size: 10px;
        background-color: var(--blue);
        background-image: url(${BGImage});
        color: var(--white);
    }
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
        font-size: var(--regularFont);
    }
    .btn, .btn-link {
        width: 10em;
        height: 2.5em;
        margin: 1em 2em;
        background: transparent;
        border: solid 2px var(--lightGrey);
        color: var(--lightGrey);
        font-size: 1.4em;
        cursor: pointer;
        :hover {
            text-shadow: 0px 0px 4px var(--white);
            border: solid 2px var(--white);
            color: var(--white);
            & svg {
                color: var(--white);
            }
        }
        > svg {
            margin-left: 0.2em;
            position: relative;
            top: 0.2em;
            color: var(--lightGrey);
        }
    }
    .btn-link {
        display: inline-block;
        padding: 0.45em;
    }

    .btn-disabled {
        color: grey;
        border-color: grey;
        :hover {
            text-shadow: none;
            border-color: grey;
            color: grey;
            & svg {
                color: grey;
            }
        }
        > svg {
            color: grey;
        }
    }

    @media only screen and (max-width: 650px) {
        .btn, .btn-link {
            display: block;
            margin: 2em auto 1em auto;
        }
        .main-container {
            margin-bottom: 20rem
        }
    }

    select {
        background-color: transparent;
        padding: 0.5em 1em;
        color: var(--grey);
        border-color: var(--grey);
        font-size: 1em;
    }
`;
