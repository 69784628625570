import GJV from 'geojson-validation';

export const ParseFile = (data: string | ArrayBuffer): string | Error => {
    try {
        const str = `${data}`;
        let json;
        if (str.includes('octet-stream')) {
            json = atob(str.substring(37));
        } else {
            json = atob(str.substring(29));
        }
        const parsed = JSON.parse(json);
        if (GJV.valid(parsed)) {
            return JSON.stringify(parsed, null, '\t');
        } else {
            throw Error('Invalid GeoJSON File');
        }
    } catch (e) {
        console.error(e);
        return e;
    }
};
