import styled from 'styled-components';

export const GenericStyles = styled.div`
    margin: 0 2em;
    padding-top: 10vh;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.4em;
        font-size: 2.2em;
    }
    h2 {
        font-weight: 200;
        text-align: center;
        font-size: 1.6em;
        font-weight: 500;
        color: var(--white);
        margin-bottom: 2em;
    }
    h3 {
        font-weight: 200;
        color: var(--lightGrey);
        text-align: center;
        font-size: 1.4em;
        margin-bottom: 0.7em;
    }
    p {
        margin-bottom: 0.2em;
    }
    ul {
        padding-left: 2rem;
    }
    .bottom-spacing {
        margin-bottom: 3em;
    }
    .left-align {
        text-align: left;
    }
    margin-bottom: 5em;
    padding: 5em 1em 0 1em;

    @media only screen and (max-width: 1200px) {
        width: 95%;
    }
`;
