import styled from 'styled-components';

interface CodeWrapperProps {
    showEditor?: boolean;
    maximizeWidth?: boolean;
}

export const CodeStyles = styled.div<CodeWrapperProps>`
    display: ${(props: CodeWrapperProps) => (!props.maximizeWidth && props.showEditor ? 'grid' : 'block')};
    grid-template-columns: ${(props: CodeWrapperProps) => (!props.maximizeWidth && props.showEditor ? '3fr 5fr' : 'none')};
    column-gap: 5px;
    margin: 0 1em;
    height: ${(props: CodeWrapperProps) => (props.maximizeWidth ? 'auto' : '90vh')};
    @media only screen and (max-width: 1150px) {
        display: block;
        height: auto;
        .one-grid {
            height: 90vh;
        }

        .second-grid {
            height: 90vh;
        }
    }
    .one-grid,
    .second-grid {
        margin-bottom: 5vh;
        height: 90vh;
        border-radius: 3px;
    }
    .one-grid div[name='container'],
    .one-grid #ace-editor {
        border-radius: 5px;
    }

    .second-grid .mapboxgl-map {
        border-radius: 5px;
    }

    .one-grid #ace-editor {
        width: 100% !important;
        height: 100% !important;
    }

    .one-grid {
        display: ${(props: CodeWrapperProps) => (props.showEditor ? 'block' : 'none')};
    }

    .errorWrap {
        border: solid 2px var(--error);
    }

    .alert-enter {
        opacity: 0;
        transform: scale(0.9);
    }
    .alert-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .alert-exit {
        opacity: 1;
    }
    .alert-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }
`;

export const TooltipStyles = styled.p`
    color: var(--error);
    font-weight: 400;
    margin: 0.5em 0 0em 1em;
`;

export const InputWrapper = styled.div`
    margin: 0 1em 0.5em 1em;
    color: var(--grey);
    .disabled {
        color: grey !important;
        > svg {
            color: grey !important;
        }
    }
    .toggle-wrapper {
        cursor: pointer;
        display: inline-block;
        margin-left: 3em;
        > svg {
            font-size: 2.5em;
            height: 1em;
            width: 1em;
            position: relative;
            top: 0.3em;
            margin-left: 0.4em;
            color: var(--grey);
        }
    }
    @media only screen and (max-width: 1150px) {
        .map-width-wrapper {
            display: none;
        }
    }
    .select-wrapper {
        display: inline-block;
    }
`;
