import React, { FC, useContext } from 'react';
import AceEditor from 'react-ace';
import { config } from 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

import { CodeContext } from '../providers/codeWrapper';
import { EditorContext } from '../providers/types';
import { Annotations } from '../providers/types';

config.set('basePath', 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/');
config.setModuleUrl('ace/mode/javascript_worker', 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-javascript.js');

export const Editor: FC = () => {
    const { code, setCode, setCodeError } = useContext<EditorContext>(CodeContext);

    const onChange = (event: string): void => {
        setCode(event);
    };
    const onValidate = (annotations: Annotations[] | undefined): void => {
        setCodeError(annotations);
    };

    return (
        <AceEditor
            value={code}
            mode="json"
            theme="monokai"
            name="ace-editor"
            onChange={onChange}
            fontSize={13}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
                useWorker: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
            }}
            onValidate={onValidate}
        />
    );
};
