import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'wouter';

const TrackPage = (): void => {
    const [location] = useLocation();
    useEffect(() => {
        ReactGA.pageview(location);
    }, [location]);
};

export default TrackPage;
