import React, { FC, useState, useEffect } from 'react';
import ReactModal from 'react-modal';

import { ModalWrapper } from '../styles/modal.styles';

ReactModal.setAppElement('#root');

export const Modal: FC = () => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const closeModal = (): void => {
        localStorage.setItem('hasUserAcceptedPrivacy', 'true');
        setIsOpen(false);
    };

    useEffect(() => {
        const hasUserAccepted: string = localStorage['hasUserAcceptedPrivacy'];
        if (hasUserAccepted) {
            setIsOpen(false);
        }
    }, [setIsOpen]);

    return (
        <ReactModal
            isOpen={modalIsOpen}
            contentLabel="Cookies and Privacy"
            bodyOpenClassName="reactPortalContent"
            overlayClassName="React-modal-overlay"
            className="React-modal-content"
        >
            <ModalWrapper>
                <h2>Cookies & Privacy Policy</h2>
                <p>
                    We use cookies to keep our site working reliably, and to retain information on how our site is used. However we also rely heavily
                    on providing personalised content in the form of ads to our users in order to fund our website. By clicking&nbsp;
                    <strong>Accept</strong> you agree to these terms and conditions. To learn more please visit our{' '}
                    <strong>
                        <a href="/privacy">Privacy</a>
                    </strong>
                    &nbsp;page
                </p>
                <button className="btn" id="accept-cookie-btn" onClick={closeModal}>
                    I Accept
                </button>
            </ModalWrapper>
        </ReactModal>
    );
};
