/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useLocation } from 'wouter';
import classNames from 'classnames';

import { HeaderStyles } from '../styles/header.styles';
import { FooterStyles } from '../styles/footer.styles';

type UrlTypes = {
    url: string;
    id: string;
    label: string;
};

const URL_LINKS: UrlTypes[] = [
    {
        url: '/',
        id: 'home',
        label: 'Home',
    },
    {
        url: '/about',
        id: 'about',
        label: 'About',
    },
    {
        url: '/privacy',
        id: 'privacy',
        label: 'Privacy Policy',
    },
];

export const Links: FC<{ isHeader?: boolean }> = ({ isHeader = true }) => {
    const [location] = useLocation();
    if (isHeader) {
        return (
            <HeaderStyles>
                <Menu>
                    {URL_LINKS.map((link) => (
                        <Link href={link.url} key={link.id}>
                            <a className="bm-item" id={link.id}>
                                <span className={classNames({ underline: location.toLowerCase() === link.url })}>{link.label}</span>
                            </a>
                        </Link>
                    ))}
                </Menu>
            </HeaderStyles>
        );
    }

    return (
        <FooterStyles>
            <ul>
                {URL_LINKS.map((link) => (
                    <li key={link.id}>
                        <Link href={link.url}>
                            <a className="bm-item">
                                <span className={classNames({ underline: location.toLowerCase() === link.url })}>{link.label}</span>
                            </a>
                        </Link>
                    </li>
                ))}
            </ul>
        </FooterStyles>
    );
};
