import { createGlobalStyle } from 'styled-components';

export const Typography = createGlobalStyle`
    body {
        font-size: var(--regularFont);
    }
    p, li {
        font-weight: 300;
    }
    h1,h2,h3,h4,h5,h6 {
        font-weight: 400;
        margin: 0;
    }
    a {
        color: var(--white);
        text-decoration: none;
    }
    a:hover {
        /* font-weight: 500; */
        text-shadow: 1px 1px 6px #ffffff;
    }
    .center {
        text-align: center;
    }
    .lightFont {
        font-weight: 200;
    }
    .mediumFont {
        font-weight: 300;
    }
`;
