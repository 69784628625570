export type Annotations = {
    column?: number | undefined;
    row?: number | undefined;
    text: string;
    type: string;
};

export type EditorContext = {
    code: undefined | string;
    setCode: React.Dispatch<React.SetStateAction<string | undefined>>;
    codeError: undefined | Annotations[];
    setCodeError: React.Dispatch<React.SetStateAction<Annotations[] | undefined>>;
    preferences: Preferences;
    setPreferences: React.Dispatch<React.SetStateAction<Preferences>>;
};

export type Props = {
    children: React.PropsWithChildren<JSX.Element[]>;
};

export enum Themes {
    DEFAULT = 'default',
    DARK = 'dark',
    BASIC = 'basic',
}

export type Preferences = {
    theme: Themes;
    showEditor: boolean;
    tiltMap: boolean;
    showBuildings: boolean;
    maximizeWidth: boolean;
};
