import React, { FC, useContext, useEffect, useState } from 'react';
import { Layer, GeoJSONLayer, Popup } from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import GJV from 'geojson-validation';
import L from 'leaflet';
import { XSquare } from 'react-feather';

import { CodeContext } from '../providers/codeWrapper';
import { EditorContext } from '../providers/types';
import {
    Mapbox,
    mapStyles,
    paintLayer,
    symbolLayout,
    symbolPaint,
    circleLayout,
    circlePaint,
    styles,
    lineLayout,
    genLinePaint,
} from '../mapbox/utils';
import { MapToolTipStyles, MapWrapperStyles } from '../styles/map.styles';

type PopupContext = [number, number] | undefined;
type LatLongType = {
    lngLat: {
        lng: number;
        lat: number;
    };
};

const MapWrapper: FC = () => {
    const { code, codeError, preferences } = useContext<EditorContext>(CodeContext);
    const [showPopup, setPopup] = useState<PopupContext>(() => undefined);

    const hideProps = () => setPopup(undefined);
    const updatePopup = (e: LatLongType): void => setPopup([e.lngLat.lng, e.lngLat.lat]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mapRef = React.useRef<any>(null);

    useEffect(() => {
        if (mapRef && mapRef.current?.state) {
            mapRef.current?.state?.map?.resize();
        }
    }, [preferences.showEditor, preferences.maximizeWidth]);

    let geojson = undefined;
    let fitBounds: [[number, number], [number, number]] | undefined = undefined;
    try {
        if (code && (!codeError || !codeError.length)) {
            geojson = JSON.parse(code);
            if (GJV.valid(geojson)) {
                const bounds = L.geoJSON(geojson, {}).getBounds();
                if (bounds.isValid()) {
                    fitBounds = [
                        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
                        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
                    ];
                }
            }
        }
    } catch (err) {
        geojson = undefined;
        fitBounds = undefined;
    }

    const linePaint = genLinePaint(preferences.theme);

    return (
        <MapWrapperStyles>
            <Mapbox
                fitBounds={fitBounds}
                style={mapStyles[preferences.theme]}
                containerStyle={styles}
                renderChildrenInPortal={true}
                pitch={preferences.tiltMap ? [60] : [0]}
                ref={mapRef}
            >
                <DrawControl position="top-left" />
                {preferences.showBuildings ? (
                    <Layer
                        id="3d-buildings"
                        sourceId="composite"
                        sourceLayer="building"
                        filter={['==', 'extrude', 'true']}
                        type="fill-extrusion"
                        minZoom={14}
                        paint={paintLayer}
                    />
                ) : undefined}
                <GeoJSONLayer
                    circleLayout={circleLayout}
                    circlePaint={circlePaint}
                    lineLayout={lineLayout}
                    linePaint={linePaint}
                    symbolLayout={symbolLayout}
                    symbolPaint={symbolPaint}
                    data={geojson}
                    lineOnMouseDown={updatePopup}
                    circleOnMouseDown={updatePopup}
                    fillOnMouseDown={updatePopup}
                />
                {showPopup ? (
                    <Popup coordinates={[showPopup[0], showPopup[1]]} anchor="bottom">
                        <MapToolTipStyles>
                            <div className="svgWrapper">
                                <XSquare onClick={hideProps} />
                            </div>
                            <p>
                                <strong>Long: </strong>
                                {showPopup[0]}
                            </p>
                            <p>
                                <strong>Lat: </strong>
                                {showPopup[1]}
                            </p>
                        </MapToolTipStyles>
                    </Popup>
                ) : undefined}
            </Mapbox>
        </MapWrapperStyles>
    );
};

export default MapWrapper;
