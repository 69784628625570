import styled from 'styled-components';

export const FooterStyles = styled.footer`
    padding: 1em;
    ul {
        display: inline-block;
        width: 100%;
        list-style-type: none;
        text-align: center;
        li {
            width: 10em;
            display: inline-block;
        }
    }
    background: #3f4964;
    .underline {
        border-bottom: solid 1px;
    }
`;
