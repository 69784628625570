import React, { FC } from 'react';
import { Route } from 'wouter';
import ReactGA from 'react-ga';

import { Links } from './components/links';
import { AboutPage } from './components/about';
import { PrivacyPolicy } from './components/privacy';
import { Modal } from './components/modal';
import { ContentWrapper } from './containers/mainWrapper';
import { CodeProvider } from './providers/codeWrapper';
import TrackPage from './utils/trackPage';

import { GlobalStyles } from './styles/global.styles';
import { Typography } from './styles/typography.styles';

import './index.css';

ReactGA.initialize('UA-193657616-1');

const App: FC = () => {
    TrackPage();
    return (
        <div className="App">
            <CodeProvider>
                <GlobalStyles />
                <Typography />

                <Links />
                <Modal />

                <Route path="/about" component={AboutPage} />
                <Route path="/privacy" component={PrivacyPolicy} />
                <Route path="/" component={ContentWrapper} />
                <Links isHeader={false} />
            </CodeProvider>
        </div>
    );
};

export default App;
