import styled from 'styled-components';

export const MapToolTipStyles = styled.div`
    color: var(--black);
    font-size: var(--smallFont);
    margin: 0;

    .svgWrapper {
        text-align: right;
        & svg {
            height: 1.2em;
            cursor: pointer;
        }
    }
`;

export const MapWrapperStyles = styled.div`
    .mapboxgl-ctrl-logo {
        display: none !important;
    }
    width: 100%;
    height: 100%;
`;
