import ReactMapboxGl from 'react-mapbox-gl';
import MapboxGL from 'mapbox-gl';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

export const Mapbox = ReactMapboxGl({
    accessToken: `${process.env.REACT_APP_MAP_BOX_TOKEN}`,
});

type StyleOptions = {
    [key: string]: string;
};

export const styles = {
    height: '100%',
    width: '100%',
    lineWidth: 3,
    lineColor: '#33C9EB',
};

export const mapStyles: StyleOptions = {
    default: 'mapbox://styles/mapbox/outdoors-v10',
    dark: 'mapbox://styles/mapbox/dark-v9',
    basic: 'mapbox://styles/mapbox/basic-v9',
};

export const paintLayer = {
    'fill-extrusion-color': '#aaa',
    'fill-extrusion-height': {
        type: 'identity' as const,
        property: 'height',
    },
    'fill-extrusion-base': {
        type: 'identity' as const,
        property: 'min_height',
    },
    'fill-extrusion-opacity': 0.6,
};

export const symbolLayout: MapboxGL.SymbolLayout = {
    'text-field': '{place}',
    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-offset': [0, 0.6],
    'text-anchor': 'top',
};
export const symbolPaint: MapboxGL.SymbolPaint = {
    'text-color': 'blue',
};

export const circleLayout: MapboxGL.CircleLayout = { visibility: 'visible' };
export const circlePaint: MapboxGL.CirclePaint = {
    'circle-color': 'red',
    'circle-radius': 8,
};

export const lineLayout: MapboxGL.LineLayout = {
    'line-cap': 'round',
    'line-join': 'miter',
    'line-miter-limit': 10,
    'line-round-limit': 10,
    'line-sort-key': 5,
};

export const genLinePaint = (style: string): MapboxGL.LinePaint => ({
    'line-opacity': 1,
    'line-color': style === 'dark' ? '#53d42b' : '#575757',
    'line-width': 10,
});

export const heatmapPaint: MapboxGL.HeatmapPaint = {
    'heatmap-weight': {
        property: 'priceIndicator',
        type: 'exponential',
        stops: [
            [0, 0],
            [5, 2],
        ],
    },

    'heatmap-intensity': {
        stops: [
            [0, 0],
            [5, 1.2],
        ],
    },

    'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(33,102,172,0)',
        0.25,
        'rgb(103,169,207)',
        0.5,
        'rgb(209,229,240)',
        0.8,
        'rgb(253,219,199)',
        1,
        'rgb(239,138,98)',
        2,
        'rgb(178,24,43)',
    ],

    'heatmap-radius': {
        stops: [
            [0, 1],
            [5, 50],
        ],
    },
};
