import styled from 'styled-components';

export const ContentStyles = styled.div`
    margin: 0 3em;
    padding-top: 30vh;
    position: relative;
    height: 85vh;

    h1 {
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.4em;
        font-size: 2.2em;
    }
    h3 {
        font-weight: 200;
        color: var(--lightGrey);
        text-align: center;
        font-size: 1.4em;
    }
    .buttons {
        margin-top: 2em;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 650px) {
        .buttons {
            display: block;
        }
    }
    .down-link {
        margin-top: 5em;
        display: block;
        > svg {
            font-size: 1em;
            height: 3.5em;
            width: 3.5em;
            color: var(--lightGrey);
            :hover {
                color: var(--white);
                font-size: 1em;
                height: 3.8em;
                width: 3.8em;
            }
        }
    }
    .buttons-wrapper {
        max-width: 40em;
        margin: 1em auto 0 auto;
    }
`;

export const BadgeStyles = styled.span`
    position: relative;
    display: block;
    width: 6em;
    font-style: italic;
    font-weight: bold;
    font-size: 0.8em;
    background-color: #d6b600;
    color: white;
    border-radius: 5px;
    padding: 2px 5px;
    text-align: center;
    margin: 0 auto;
    left: 6em;
    top: -10em;
`;
