import React, { FC } from 'react';

import { GenericStyles } from '../styles/generic.styles';

export const AboutPage: FC = () => {
    return (
        <main className="main-container">
            <GenericStyles>
                <h2 className="left-align">Geo-Json.com</h2>
                <p className="bottom-spacing left-align">
                    Welcome to Geo-Json.com a free and open tool for creating, uploading and editing GeoJSON data.
                </p>
                <h2 className="left-align">Features</h2>
                <p className="bottom-spacing left-align">
                    <ul>
                        <li>Ability to upload GeoJson and JSON files for representing maps </li>
                        <li>Text Editor with syntax highlighting, line breaks, collapsible fields and toggle mode</li>
                        <li>3D building render within map</li>
                        <li>Tilt functionality</li>

                        <li>Ability to draw and edit lines and shapes within a map using GL Draw library</li>
                        <li>
                            Support for Dark, basic and default modes based on <a href="https://mapbox.com">mapbox</a> tiles
                        </li>
                    </ul>
                </p>
                <h3 className="left-align">Privacy Policy & Terms of use</h3>
                <p>
                    <a href="/privacy">https://geo-json.com/privacy</a>
                </p>
            </GenericStyles>
        </main>
    );
};
