import styled from 'styled-components';

export const ModalWrapper = styled.div`
    h2 {
        font-weight: 200;
        font-size: 1.6em;
        font-weight: 500;
        color: var(--white);
    }
    .btn {
        margin-left: 0px;
    }
`;
