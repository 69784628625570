import React, { useState, createContext } from 'react';
// Types
import { EditorContext, Props, Annotations, Preferences, Themes } from './types';

export const CodeContext = createContext<EditorContext>({} as EditorContext);

const initialValue = `{
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [ -73.9939, 40.7114 ],
                    [ -73.9932, 40.7043 ]
                ]
            }
        }
    ]
}`;

export const CodeProvider: React.FC<Props> = ({ children }: Props) => {
    const [code, setCode] = useState<string | undefined>(initialValue);
    const [codeError, setCodeError] = useState<Annotations[] | undefined>([]);
    const [preferences, setPreferences] = useState<Preferences>({
        theme: Themes.DEFAULT,
        showEditor: true,
        tiltMap: true,
        showBuildings: true,
        maximizeWidth: false,
    });

    return <CodeContext.Provider value={{ code, setCode, codeError, setCodeError, preferences, setPreferences }}>{children}</CodeContext.Provider>;
};
